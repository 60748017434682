import React from "react"
import { Link } from "gatsby"
import config from "../../../data/SiteConfig"
import "./footer.scss"

const Footer = props => {
  const { links } = props
  const copyright = config.copyright
  const middleLeftLinks = []
  const middleRightLinks = []
  const footerBannerLinks = []

  links.edges
    .sort((edge1, edge2) => edge2.node.order - edge1.node.order)
    .forEach(({ node }) => {
      if (node.section === "footer-middle-left") {
        middleLeftLinks.push(
          <div key={node.label}>
            <Link className="footer-link" to={node.url}>
              {node.label}
            </Link>
          </div>
        )
      } else if (node.section === "footer-middle-right") {
        middleRightLinks.push(
          <div key={node.label}>
            <Link className="footer-link" to={node.url}>
              {node.label}
            </Link>
          </div>
        )
      } else if (node.section === "footer-banner") {
        footerBannerLinks.push(
          <a
            key={node.label}
            className="footer-banner-link"
            target="_blank"
            rel="noreferrer"
            href={node.url}
          >
            {node.label}
          </a>
        )
      }
    })

  if (!copyright) {
    return null
  }

  return (
    <div>
      <div className="footer-container">
        <footer className="footer">
          <div className="footer-section">
            <div className="footer-title">Om Everysport.tv</div>
            <div>
              {" "}
              Everysport.tv guidar dig livesända matcher på TV och stream. Vi
              vill erbjuda ett snabbt sätt att hitta rätt tv-kanal eller
              streamingsajt för att uppleva sport live. I möjligaste mån vill vi
              djuplänka direkt till rätt match/sändning och till dom bästa
              oddsen på livesport.
              <a
                href="mailto:admin@everysport.tv"
                className="footer-section-link"
              >
                {" "}
                Tipsa oss
              </a>{" "}
              gärna om sporter, kanaler och matcher vi kan ha missat.
            </div>
            <img
              src="/logos/etv/etv2.png"
              alt="everysport"
              className="footer-logo"
            />
          </div>
          <div className="footer-section">
            <div className="footer-title">Sport på TV</div>
            {middleLeftLinks}
          </div>
          <div className="footer-section">
            <div className="footer-title">Populära Lag</div>
            {middleRightLinks}
          </div>
          <div className="footer-section">
            <div className="footer-title">Social Medier</div>
            <a href={config.facebookUrl} target="_blank" rel="noreferrer">
              <img
                className="footer-social-icon"
                src="/social/fb-light.png"
                alt="Everysport TV at Facebook"
              />
            </a>
            <a href={config.twitterUrl} target="_blank" rel="noreferrer">
              <img
                className="footer-social-icon"
                src="/social/tw-light.png"
                alt="Everysport TV at Twitter"
              />
            </a>
          </div>
        </footer>
      </div>
      <div className="footer-banner-container">
        <div className="footer-banner">
          <span className="footer-banner-title">
            En del av Everysport Media Group
          </span>
          <span className="footer-banner-separator">|</span>
          {footerBannerLinks}
        </div>
      </div>
    </div>
  )
}

export default Footer
