import React from "react"
import { Link } from "gatsby"
import "./breadcrumbs.scss"

const _ = require("lodash")

const Breadcrumbs = ({ links }) => {
  const lastIndex = links.length - 1
  const breadcrumbs = _.map(links, (link, index) => (
    <span key={link.label} className="bcc-breadcrumbs-item">
      <Link to={link.uri} className="bcc-breadcrumbs-item-link">
        {link.label}
      </Link>
      {index === lastIndex ? null : (
        <span className="bcc-breadcrumbs-item-separator"> &#9658; </span>
      )}
    </span>
  ))

  return (
    <div className="bcc">
      <div className="bcc-breadcrumbs">{breadcrumbs}</div>
    </div>
  )
}

export default Breadcrumbs
