import moment from "moment"
import "moment/locale/sv"
import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Channel from "../../components/Channel/channel"
import EventGroups from "../../components/EventGroups/eventGroups"
import Footer from "../../components/Footer/footer"
import Breadcrumbs from "../../components/Breadcrumbs/breadcrumbs"
// import Odds from "../../components/Odds/odds"
import { FULL_DAY_FORMAT } from "../../utils/dateHelper"
import { getEventName } from "../../utils/eventHelper"
import "./event-template.scss"
import config from "../../../data/SiteConfig"
import BaseLayout from "../../components/Layout/layout"
import { graphql } from "gatsby"

export default class EventTemplate extends React.Component {
  eventTitle() {
    const { homeTeam, awayTeam, sport, league } = this.props.data.eventDetails
    if (homeTeam && awayTeam) {
      return (
        <Helmet>
          <title>
            {homeTeam.name} - {awayTeam.name} och andra {league.name}-matcher på
            tv och stream - Everysport.tv
          </title>
          <meta
            name="og:title"
            content={`${homeTeam.name} - ${awayTeam.name} och andra ${league.name}-matcher
            på tv och stream - Everysport.tv`}
          />
          <meta
            name="twitter:title"
            content={`${homeTeam.name} - ${awayTeam.name} och andra ${league.name}-matcher
            på tv och stream - Everysport.tv`}
          />
        </Helmet>
      )
    }
    return (
      <Helmet>
        <title>
          {sport.name} {league.name} på tv och stream - Everysport.tv
        </title>
        <meta
          name="og:title"
          content={`${sport.name} ${league.name} på tv och stream - Everysport.tv`}
        />
        <meta
          name="twitter:title"
          content={`${sport.name} ${league.name} på tv och stream - Everysport.tv`}
        />
      </Helmet>
    )
  }

  mobileLeagueInfo() {
    const {
      homeTeam,
      awayTeam,
      detail,
      info,
      sport,
      league,
    } = this.props.data.eventDetails
    const eventInfo =
      info && info.info && info.info.length > 4 ? info.info : null
    const eventDetail =
      detail && detail.detail && detail.detail.length > 4 ? detail.detail : null
    if (homeTeam && awayTeam) {
      return (
        <div className="mobile-league-info">
          <span>{`${sport.name} - `}</span>
          <span>{league.name}</span>
          <br />
          <span>{eventDetail}</span>
          <br />
          <span>{eventInfo}</span>
        </div>
      )
    }
    return (
      <div className="mobile-league-info">
        <span>{eventDetail}</span>
        <br />
        <span>{eventInfo}</span>
      </div>
    )
  }

  renderMiddleMobileRow() {
    const { channels, channelLinks } = this.props.data.eventDetails

    return (
      <div className="event-details-middle-mobile-row">
        {this.mobileLeagueInfo()}
        <div className="mobile-channels-container">
          {channels &&
            channels.map(channel => (
              <Channel
                key={`details${channel.name}`}
                channel={channel}
                links={channelLinks}
              />
            ))}
        </div>
      </div>
    )
  }

  renderMiddleRow() {
    const {
      homeTeam,
      awayTeam,
      detail,
      info,
      sport,
      league,
      group,
    } = this.props.data.eventDetails
    const eventInfo =
      info && info.info && info.info.length > 4 ? info.info : null
    const eventDetail =
      detail && detail.detail && detail.detail.length > 4 ? detail.detail : null
    if (homeTeam && awayTeam) {
      const leagueLogo = league.logo
        ? league.logo.file.url
        : `/logos/competition/${league.logoUri}`
      const logoFolder =
        sport.code && sport.code.replace(/\s+/g, "").toLowerCase()
      const homeTeamLogo = homeTeam.logoUrl
        ? homeTeam.logoUrl
        : homeTeam.betradarId && logoFolder
        ? `/logos/team/${logoFolder}/${homeTeam.betradarId}.png`
        : "/logos/team/fallback.png"
      const awayTeamLogo = awayTeam.logoUrl
        ? awayTeam.logoUrl
        : awayTeam.betradarId && logoFolder
        ? `/logos/team/${logoFolder}/${awayTeam.betradarId}.png`
        : "/logos/team/fallback.png"
      return (
        <div className="event-details-middle-row">
          <Link className="team" to={homeTeam.uri}>
            <h1 className="team-name team-name--left">{homeTeam.name}</h1>
            <img
              src={homeTeamLogo}
              onError={e =>
                e.target.setAttribute("src", "/logos/team/fallback.png")
              }
              className="event-details-team-logo"
              alt={homeTeam.name}
            />
          </Link>
          <div className="event-info">
            <Link className="link" to={`${league.uri}`}>
              <img
                src={`${leagueLogo}`}
                onError={e =>
                  e.target.setAttribute(
                    "src",
                    "/logos/competition/fallback.png"
                  )
                }
                className="event-details-league-logo"
                alt={league.name}
              />
            </Link>
            <div className="league-info">
              <span className="league-info-sport">{sport.name}</span>
              <br />
              <span>{league.name}</span>
              <br />
              <span>{eventDetail}</span>
              <br />
              <span>{eventInfo}</span>
            </div>
          </div>
          <Link className="team" to={awayTeam.uri}>
            <img
              src={awayTeamLogo}
              onError={e =>
                e.target.setAttribute("src", "/logos/team/fallback.png")
              }
              className="event-details-team-logo"
              alt={awayTeam.name}
            />
            <h1 className="team-name team-name--right">{awayTeam.name}</h1>
          </Link>
        </div>
      )
    }

    let groupLogo
    let groupUri
    if (sport.code === "WINTER SPORTS") {
      groupLogo =
        league.logoUri && league.logoUri !== "fallback.png"
          ? league.logoUri
          : league.logoUriNeg
      groupUri = league.uri
    } else {
      groupLogo =
        group.logoUri && group.logoUri !== "fallback.png"
          ? group.logoUri
          : group.logoUriNeg
      groupUri = group.uri
    }
    return (
      <div className="event-details-middle-row">
        <div className="event-info-no-team">
          <Link className="link" to={`${groupUri}`}>
            <img
              src={`/logos/competition/${groupLogo}`}
              onError={e =>
                e.target.setAttribute("src", "/logos/competition/fallback.png")
              }
              className="event-details-league-logo"
              alt={league.name}
            />
          </Link>
          <div className="event-info-no-team-details">
            <span>{group.name}</span> - <span>{league.name}</span>
            <br />
            <div className="event-info-no-team-details-detail">
              <span>{eventDetail}</span>
              <br />
              <span>{eventInfo}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { data } = this.props
    const {
      location,
      channels,
      date,
      channelLinks,
      // bookmaker,
      // bookmakerUrl,
      // betradarId,
      // everysportId,
      sport,
      homeTeam,
      awayTeam,
      detail,
    } = data.eventDetails

    const eventDate = moment(date).locale("sv")
    const dateStr = eventDate.format("HH:mm - dddd D/M")
    const breadcrumbs = []
    const sportUrl = `/sv/${sport.name.replace(" ", "-")}`
    const eventDayString = eventDate.format(FULL_DAY_FORMAT)
    breadcrumbs.push({ uri: sportUrl, label: sport.name })
    breadcrumbs.push({
      uri: `${sportUrl}/${eventDayString}`,
      label: eventDayString,
    })
    breadcrumbs.push({
      uri: this.props.location.pathname,
      label: getEventName(homeTeam, awayTeam, detail, sport),
    })

    return (
      <BaseLayout>
        {this.eventTitle()}
        <Breadcrumbs links={breadcrumbs} />
        <div className="event-details-container">
          <div className="event-details">
            <div className="event-details-top-row">
              <div className="social-icons">
                <img
                  src="/social/share-dark.png"
                  alt="share"
                  className="social-icon-share"
                />
                <a href={config.facebookUrl} target="_blank" rel="noreferrer">
                  <img
                    src="/social/fb-dark.png"
                    alt="facebook"
                    className="social-icon"
                  />
                </a>
                <a href={config.twitterUrl} target="_blank" rel="noreferrer">
                  <img
                    src="/social/tw-dark.png"
                    alt="twitter"
                    className="social-icon"
                  />
                </a>
              </div>
              <div className="date-location-container">
                <div className="date">{dateStr}</div>
                <div className="location">{location}</div>
              </div>
              <div className="channels-container">
                <img src={"/menu/tv.png"} className="tv-icon " alt="TV" />
                {channels &&
                  channels.map(channel => (
                    <Channel
                      key={`details${channel.name}`}
                      channel={channel}
                      links={channelLinks}
                    />
                  ))}
              </div>
            </div>
            {this.renderMiddleRow()}
            {this.renderMiddleMobileRow()}
            {/*<div className="event-details-bottom-row">*/}
            {/*  <Odds*/}
            {/*    bookmaker={bookmaker}*/}
            {/*    bookmakerUrl={bookmakerUrl}*/}
            {/*    betradarId={betradarId}*/}
            {/*    everysportId={everysportId}*/}
            {/*    isMobile={false}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="event-groups-container">
          <EventGroups
            eventEdges={data.events.edges}
            selectedDay={eventDayString}
            numberOfDays={1}
          />
        </div>
        <Footer links={this.props.pageContext.allLinks} />
      </BaseLayout>
    )
  }
}

export const pageQuery = graphql`
  query eventQuery($id: String!, $sportId: String!) {
    eventDetails: contentfulEvent(id: { eq: $id }) {
      date
      betradarId
      everysportId
      bookmakerUrl
#      bookmaker {
#        name
#        externalUrl
#        logo {
#          file {
#            url
#          }
#        }
#        mobileLogo {
#          file {
#            url
#          }
#        }
#      }
      location
      detail {
        detail
      }
      info {
        info
      }
      homeTeam {
        name
        uri
        betradarId
        logoUrl
      }
      awayTeam {
        name
        uri
        betradarId
        logoUrl
      }
      league {
        name
        uri
        logoUri
        logoUriNeg
        logo {
          file {
            url
          }
        }
      }
      group {
        name
        uri
        logoUri
        logoUriNeg
      }
      sport {
        name
        code
        contentful_id
      }
      channelLinks {
        channel
        externalUrl
      }
      channels {
        id
        name
        externalUrl
        logo {
          file {
            url
          }
        }
      }
    }
    events: allContentfulEvent(
      filter: { sportId: { eq: $sportId }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            uri
          }
          sport {
            code
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
